import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthLine from "./pages/auth-line";
import LineCallback from "./pages/callback";
import LogoutPage from "./pages/logout";

const AppRoutes = (props) => {
  const { singleSpa } = props;
  return (
    <Routes>
      <Route path="auth">
        <Route path="line">
          <Route path="" element={<AuthLine singleSpa={singleSpa} />} />
          {/* <Route
            path="callback"
            element={<LineCallback singleSpa={singleSpa} />}
          /> */}
        </Route>
        <Route path="logout" element={<LogoutPage singleSpa={singleSpa} />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
