import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes";

export default function Root(props) {
  return (
    <Router>
      <AppRoutes singleSpa={props.singleSpa} />
    </Router>
  );
}
