import { useEffect, useState } from "react";
import { isMobileBrowser } from "@orangep/util";
import LineLoginButton from "../components/line-login-button";

const AuthLine = (props) => {
  const { singleSpa } = props;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      return singleSpa.navigateToUrl("/auth/line/callback");
    }
  }, [isLoggedIn, singleSpa]);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "80vh",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        {!isLoggedIn ? (
          <LineLoginButton
            buttonType={isMobileBrowser() ? "MOBILE" : "DESKTOP"}
            onClick={() => {
              window.location.href = "/";
              // liff.login({
              //   redirectUri: `${window.location.origin}/auth/line/callback`,
              // });
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AuthLine;
