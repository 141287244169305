import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { logout, getUserObservable } from "@orangep/util";

const LogoutPage = (_props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const user$ = getUserObservable().subscribe((userObject) => {
      if (userObject) {
        logout().then(() => {
          window.location.href = "/auth/line";
        });
      }
    });
    return () => {
      user$.unsubscribe();
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "80vh",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Kanit",
        textAlign: "center",
      }}
    >
      <div>
        <h1>คุณออกจากระบบแล้ว</h1>
        <h2>You have been logged out</h2>
      </div>
    </div>
  );
};

export default LogoutPage;
