import { MouseEventHandler, useMemo, useState } from "react";

import LoginButtonDesktopBase from "../assets/line/44dp/btn_login_base.png";
import LoginButtonDesktopHover from "../assets/line/44dp/btn_login_hover.png";
import LoginButtonDesktopPress from "../assets/line/44dp/btn_login_press.png";

import LoginButtonMobileBase from "../assets/line/32dp/btn_login_base.png";
import LoginButtonMobileHover from "../assets/line/32dp/btn_login_hover.png";
import LoginButtonMobilePress from "../assets/line/32dp/btn_login_press.png";

type LineLoginButtonProps = {
  buttonType: "DESKTOP" | "MOBILE";
  onClick?: MouseEventHandler<HTMLInputElement>;
};

type LineLoginButtons = {
  base: string;
  hover: string;
  press: string;
};

const LineLoginButton = ({ buttonType, onClick }: LineLoginButtonProps) => {
  const loginButton = useMemo<LineLoginButtons>(() => {
    if (buttonType === "DESKTOP") {
      return {
        base: LoginButtonDesktopBase,
        hover: LoginButtonDesktopHover,
        press: LoginButtonDesktopPress,
      };
    }
    return {
      base: LoginButtonMobileBase,
      hover: LoginButtonMobileHover,
      press: LoginButtonMobilePress,
    };
  }, [buttonType]);

  return (
    <input
      type="image"
      src={loginButton.base}
      onClick={onClick}
      alt="Login with Line"
    />
  );
};

export default LineLoginButton;
